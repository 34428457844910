import { isEmpty, isNil, path, omit } from '@soltalabs/ramda-extra'

class Enum {
  constructor(initializer) {
    validateInitializer(initializer)
    instantiateInternalDataStructure.call(this)
    populateEnumWithMembers.call(this, initializer)

    // eslint-disable-next-line
    function validateInitializer(initializer) {
      if (isEmpty(initializer)) {
        throw new TypeError(
          `Enum initializer expected object with properties, but was passed ${String(
            initializer
          )} instead`
        )
      }
    }

    function instantiateInternalDataStructure() {
      defineLockedProperty.call(this, '__members_map__', new Map())
      defineLockedProperty.call(this, '__members_list__', [])
    }

    function defineLockedProperty(key, value) {
      Object.defineProperty(this, key, {
        value,
        enumerable: false,
        writable: false,
        configurable: false,
      })
    }

    // eslint-disable-next-line
    function populateEnumWithMembers(initializer) {
      const initializerEntries = Object.entries(initializer)

      for (
        let memberIndex = 0;
        memberIndex < initializerEntries.length;
        memberIndex += 1
      ) {
        const [memberKey, memberProperties] = initializerEntries[memberIndex]
        const memberSymbol = Symbol(memberKey)

        const member = {
          key: memberKey,
          symbol: memberSymbol,
          properties: memberProperties,
        }

        ensureMembersPropertiesHasValue(memberProperties, memberIndex)
        setupDotSyntaxSymbolAccess.call(this, memberKey, memberSymbol)
        setupBracketSyntaxPropertiesAccess.call(this, memberSymbol, memberProperties)
        addMemberToInternalDataStructure.call(this, member)
      }
    }

    function ensureMembersPropertiesHasValue(memberProperties, memberIndex) {
      if (isNil(memberProperties.value)) {
        // eslint-disable-next-line
        memberProperties.value = memberIndex
      }
    }

    function setupDotSyntaxSymbolAccess(key, symbol) {
      defineLockedProperty.call(this, key, symbol)
    }

    function setupBracketSyntaxPropertiesAccess(symbol, properties) {
      defineLockedProperty.call(this, symbol, properties)
    }

    function addMemberToInternalDataStructure(member) {
      const { key, symbol, properties } = member

      this.__members_map__.set(key, member)
      this.__members_map__.set(symbol, member)
      this.__members_map__.set(properties.value, member)
      this.__members_list__.push(member)
    }
  }

  static keyOf(accessor, E) {
    return getDataOfMemberUsingAccessor(E, accessor, 'key')
  }

  keyOf(accessor) {
    return Enum.keyOf(accessor, this)
  }

  static symbolOf(accessor, E) {
    return getDataOfMemberUsingAccessor(E, accessor, 'symbol')
  }

  symbolOf(accessor) {
    return Enum.symbolOf(accessor, this)
  }

  static valueOf(accessor, E) {
    return getDataOfMemberUsingAccessor(E, accessor, 'properties.value')
  }

  valueOf(accessor) {
    return Enum.valueOf(accessor, this)
  }

  static propertiesOf(accessor, E) {
    return getDataOfMemberUsingAccessor(E, accessor, 'properties')
  }

  propertiesOf(accessor) {
    return Enum.propertiesOf(accessor, this)
  }

  static keys(E) {
    return mapMemberListToPropertyList(E, 'key')
  }

  keys() {
    return Enum.keys(this)
  }

  static symbols(E) {
    return mapMemberListToPropertyList(E, 'symbol')
  }

  symbols() {
    return Enum.symbols(this)
  }

  static values(E) {
    return mapMemberListToPropertyList(E, 'properties.value')
  }

  values() {
    return Enum.values(this)
  }

  static properties(E) {
    return mapMemberListToPropertyList(E, 'properties')
  }

  properties() {
    return Enum.properties(this)
  }

  static has(symbol, E) {
    return !isNil(E[symbol])
  }

  has(symbol) {
    return Enum.has(symbol, this)
  }

  static hasValue(value, E) {
    return Enum.values(E).includes(value)
  }

  hasValue(value) {
    return Enum.hasValue(value, this)
  }

  toJSON() {
    return this.__members_list__.map((member) => omit('symbol', member))
  }

  [Symbol.toStringTag] = 'Enum';

  [Symbol.iterator] = function* () {
    for (const member of this.__members_list__) {
      yield member
    }
  }
}

function getDataOfMemberUsingAccessor(E, accessor, dataPath) {
  const member = E.__members_map__.get(accessor)
  const memberData = path(dataPath.split('.'), member)

  return memberData
}

function mapMemberListToPropertyList(E, propertyPath) {
  return E.__members_list__.map((member) => path(propertyPath.split('.'), member))
}

export { Enum }

/*
 * We use this file to set up any non-standard globals that may be used by this
 * app. It is important that these globals are the first import for the whole
 * app. See the top-level index.js for usage.
 */

import { Enum } from 'lib/enum'

Object.defineProperty(global, 'Enum', {
  value: Enum,
  enumerable: true,
  writable: false,
  configurable: false,
})
